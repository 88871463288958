import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { GroupMember } from './../../models/group-member';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { User } from 'src/app/models/user';

@Injectable({
  providedIn: 'root'
})
export class GroupService {
  env = environment;
  groupMemberList: GroupMember[] = [];

  constructor(
    private http: HttpClient,
  ) { }

  getGroupsList(authUser: User): Observable<any> {
    const body = {
      userID: authUser.userId,
      sessionToken: authUser.token,
      deviceId: authUser.deviceId
    }
    const url = this.env.apiUrl + '/getGroupsList';

    return this.http.post(url, body);
  }

  getDepartments(groupCode: any, authUser: User): Observable<any> {
    const body = {
      userID: authUser.userId,
      grpCode: groupCode,
      sessionToken: authUser.token,
      deviceId: authUser.deviceId
    }
    const url = this.env.apiUrl + '/getDepartments';
    // console.log(url, body);

    return this.http.post(url, body);
  }

  getGroupMembers(groupCode: any, dptCode: any, authUser: User): Observable<any> {
    const body = {
      userID: authUser.userId,
      group: groupCode,
      deptCode: dptCode,
      sessionToken: authUser.token,
      deviceId: authUser.deviceId
    }
    const url = this.env.apiUrl + '/getGroupMembers';
    // console.log(url, body);

    return this.http.post(url, body);
  }

  groupMemberSearch(groupCode: any, searchStr: any, authUser: User): Observable<any> {
    const url = this.env.apiUrl + '/getByLastFirstGroup';
    let name = searchStr;
    if (isNaN(searchStr)) {
      const regex = new RegExp('\\s*,\\s*'); // regular expression to split comma delimited full name
      const nameArr = searchStr.split(regex);
      name = {
        last: nameArr[0] === undefined ? '' : nameArr[0],
        first: nameArr[1] === undefined ? '' : nameArr[1]
      };
    } else {
      name = searchStr;
    }
    const body = {
      userID: authUser.userId,
      name,
      grpCode: groupCode,
      sessionToken: authUser.token,
      deviceId: authUser.deviceId
    }

    return this.http.post(url, body);
  }

  getLeadership(groupCode: any, authUser: User): Observable<any> {
    const body = {
      userID: authUser.userId,
      grpCode: groupCode,
      sessionToken: authUser.token,
      deviceId: authUser.deviceId
    }
    const url = this.env.apiUrl + '/getGroupLead';
    console.log(url, body);
    return this.http.post(url, body);
  }

  // public extractData(res: any) {
  //   const body = res.json();
  //   // console.log("extractData");
  //   // console.log(JSON.stringify(res));
  //   console.log(body);

  //   return body || {};
  // }

  handleError(error: any): Promise<any> {
    console.log(error);
    const errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    console.error(errMsg); // log to console instead
    return Promise.reject(errMsg);
    // return error;
  }

}
